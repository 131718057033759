<template>
  <footer>
    <div class="social">
      <a href="https://vk.com/lalearu" target="_blank">
        <img
          @mouseenter="hovers = 'vk'"
          @mouseleave="hovers = ''"
          :src="`/static_files/icons/social/vk${hovers === 'vk' ? `_hover` : ''}.svg`"
          alt="LALEA во ВКонтакте"
        >
      </a>
      <a href="https://ok.ru/group/70000005003866" target="_blank">
        <img
          @mouseenter="hovers = 'ok'"
          @mouseleave="hovers = ''"
          :src="`/static_files/icons/social/ok${hovers === 'ok' ? `_hover` : ''}.svg`"
          alt="LALEA в Одноклассники"
        >
      </a>
      <a href="https://zen.yandex.ru/id/621f1e4eec8fe97bf5409b23" target="_blank">
        <img
          @mouseenter="hovers = 'zen'"
          @mouseleave="hovers = ''"
          :src="`/static_files/icons/social/zen${hovers === 'zen' ? `_hover` : ''}.svg`"
          alt="LALEA в Дзен"
        >
      </a>
      <a href="https://www.instagram.com/lalearu/" target="_blank">
        <img
          @mouseenter="hovers = 'insta'"
          @mouseleave="hovers = ''"
          :src="`/static_files/icons/social/insta${hovers === 'insta' ? `_hover` : ''}.svg`"
          alt="LALEA в Instagram"
        >
      </a>
      <a href="https://t.me/lalearu/" target="_blank">
        <img
          @mouseenter="hovers = 'telegram'"
          @mouseleave="hovers = ''"
          :src="`/static_files/icons/social/telegram${hovers === 'telegram' ? `_hover` : ''}.png`"
          alt="LALEA в Telegram"
        >
      </a>
    </div>

    <ul>
      <li>
        <nuxt-link to="/about">О проекте</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/termsofuse">Пользовательское соглашение</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/support">Служба поддержки</nuxt-link>
      </li>
    </ul>

    <p>Copyright (с) «LALEA», {{ new Date().getFullYear() }}.</p>

    <p>Любое воспроизведение материалов сайта без разрешения редакции воспрещается.</p>

    <p>
      Контактные данные для государственных органов (в том числе, для Роскомнадзора):
      <br>
      Эл. почта: support@lalea.ru
    </p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      hovers: ''
    }
  }
}
</script>

<style src="~/assets/footer.styl" lang="stylus" />
